<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          class="button-menu-warehouse"
        >
          <v-icon
            color="#000"
            size="18px"
          >
            mdi-dots-vertical
          </v-icon>
        </v-btn>
      </template>
      <v-list
        class="warehouse-menu-options"
        min-width="150px"
      >
        <v-list-item @click="markerFavorite">
          <v-list-item-title class="button-select-option mon-regular">{{ texts.warehouse.menu.textFavorite }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="editWarehouse">
          <v-list-item-title class="button-select-option mon-regular">{{ texts.warehouse.menu.textEdit }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="setDialogDelete">
          <v-list-item-title class="button-select-option mon-regular">{{ texts.warehouse.menu.textDelete }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "WarehouseMenuLayout",
  props: {
    texts: {
      type: Object,
    },
    arr: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
    };
  },
  methods: {
    markerFavorite: function () {
      const payload = {};

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/warehouses/${this.arr.sCustomerWarehouseId}`,
        payload,
        config
      )
        .then((response) => {
          this.$store.commit("refresher", true);
          this.mixSuccess(response.data.message);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    setDialogDelete: function () {
      this.$store.commit("setDialogDelete", {
        active: true,
        title: this.texts.warehouse.textTitleDelete,
        api: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/warehouses/${this.arr.sCustomerWarehouseId}`,
        redirect: "",
      });
    },
    editWarehouse: function () {
      this.$router.push({
        name: "EditWarehouse",
        params: {
          id: this.arr.sCustomerWarehouseId,
        },
      });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
};
</script>

<style scope>
.button-menu-warehouse {
  position: absolute;
  top: 5px;
  right: 5px;
}

.warehouse-menu-options .v-list-item {
  min-height: 30px !important;
}

.button-select-option {
  font-size: 14px;
}
</style>